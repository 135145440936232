<template>
  <div>
    <div class="banner" id="newest_banner1">
      <div
        class="banner"
        id="newest_banner2"
        :style="'background-image: url(' + banner + ')'"
        ref="newest_banner"
      >
        <!-- <img :src="banner" /> -->
        <span style="display: none">新闻中心<i>News Center</i></span>
      </div>
    </div>

    <section class="ab-item1">
      <!-- <a href="/">{{ $t("indexnav.Home") }}</a> >
            <a>{{ $t("indexnav.News Center") }}</a> -->
      <bread-crumb />
    </section>
    <!-- <div>
            <a
              :title="item.title"
              v-for="(item, index) in articleList"
              :key="index"
              @click="gotoArticleDetail(item.id)"
            >
              <div>
                <img :src="item.img" :alt="item.title" />
              </div>
              <strong>{{ item.title }}</strong>
              <span>{{ item.publishDate }}</span>
              <p>{{ item.subTitle }}</p>
            </a>
          </div> -->

    <figure>
      <figcaption v-if="false">
        {{ nowType }}
      </figcaption>
      <div>
        <a
          v-for="(item, index) in articleList"
          :key="index"
          @click="gotoArticleDetail(item)"
          style="cursor: pointer"
        >
          <div>
            <img :src="item.img" :onerror="error" class="wenzhangImg" />
          </div>
          <strong>{{ item.title }}</strong>
          <span>{{ item.publishDate }}</span>
          <p :style="{height:$store.state.nowlanguage == 'en'?'34px':'4.8em'}">{{ item.subTitle }}</p>
        </a>
      </div>
    </figure>

    <div class="page" style="display: flex; justify-content: center">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        :total="totalCount"
        :page-size="9"
        :current-page="pageNum"
        @current-change="handleCurrentPageChange"
        style="display: flex"
      ></el-pagination>
    </div>

    <!-- <div class="page">
          <ul>
            <li class="thisclass">1</li>
            <li><a href="list_5_2.html">2</a></li>
            <li><a href="list_5_3.html">3</a></li>
            <li><a href="list_5_4.html">4</a></li>
            <li><a href="list_5_2.html">下一页</a></li>
          </ul>
        </div> -->
  </div>
</template>

<script>
import { getArticle, getAlltype } from "@/api/news";
import { PageType } from "@/utils/constant";
import { getAction } from "@/api/manage";

export default {
  data() {
    return {
      pageNum: 0,
      error:
        'this.src="' +
        require("../../assets/images/index/defaultNewImg.jpg") +
        '"',
      articleList: [],
      totalCount: 0,
      AllTypeList: [], //所有文章类别
      nowType: "",
      nowLanguageData: "sample",
      banner: "",
      flag: false,
    };
  },
  created() {
    // window.vue = this;
    // 初始化banner
    let that = this;
    this.$nextTick(() => {
      this.flag = false;
      getAction("/jeecg-system/common/frontend/advertise/active_list", {
        typeCode: PageType.XINWENZHONGXIN,
      })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            if (res.result.records[0].isMultipatLanguge !== "0") {
              if (this.$store.state.nowlanguage == "zh")
                that.banner = res.result.records[0].sampleImg;
              if (this.$store.state.nowlanguage == "tr")
                that.banner = res.result.records[0].tranditionalImg;
              if (this.$store.state.nowlanguage == "en")
                that.banner = res.result.records[0].enImg;
            } else {
              that.banner = res.result.records[0].sampleImg;
            }
            console.log(that.banner);
          }
        })
        // .finally(() => (this.flag = true));
      // let newest_banner2 = document.getElementById('newest_banner2')
      // console.log(newest_banner2);
      // newest_banner2.style.backgroundImage='url('+that.banner+')'
    });

    // 加载文章数据
    if (this.$store.state.nowlanguage == "zh") {
      this.nowLanguageData = "sample";
    }
    if (this.$store.state.nowlanguage == "tr") {
      this.nowLanguageData = "tranditional";
    }
    if (this.$store.state.nowlanguage == "EN") {
      this.nowLanguageData = "EN";
    }
    if (sessionStorage.currentPage) {
      console.log(JSON.parse(sessionStorage.currentPage));
      this.handleCurrentPageChange(JSON.parse(sessionStorage.currentPage));
    } else {
      this.handleCurrentPageChange(0);
    }
    getAlltype().then((res) => {
      for (let i of res.result) {
        if (i.id == this.$route.query.type) {
          this.nowType = i.name;
        }
      }
    });
  },
  methods: {
    // 跳转页码
    handleCurrentPageChange(currentPage) {
      sessionStorage.currentPage = currentPage;
      let that = this;
      getArticle({
        typeId: this.$route.query.type,
        // languageType: this.nowLanguageData,
        languageType: this.getlang(),
        pageNo: currentPage,
        pageSize: 9,
      }).then((res) => {
        that.articleList = res.result.records;
        that.totalCount = res.result.total;
        that.pageNum = currentPage;
        scrollTo(0, 0);
      });
    },
    getlang(newvalue, oldvalue) {
      if (this.$store.state.nowlanguage == "zh") {
        this.nowLanguageData = "sample";
        return "sample";
      }
      if (this.$store.state.nowlanguage == "tr") {
        this.nowLanguageData = "tranditional";
        return "tranditional";
      }
      if (this.$store.state.nowlanguage == "en") {
        this.nowLanguageData = "EN";
        return "EN";
      }
    },
    gotoArticleDetail(item) {
      if (item.outUrl != "") {
        window.location.href = item.outUrl;
      } else {
        this.$router.push("/newsinfo?id=" + item.id);
      }
    },
    setLanguage() {},
  },
  computed: {
    getnowlanguage() {
      return this.$store.state.nowlanguage;
    },
  },
  watch: {
    banner(val){
      console.log(val)
    },
    getnowlanguage(newValue, oldValue) {
      if (newValue == "zh") {
        this.nowLanguageData = "sample";
      }
      if (newValue == "tr") {
        this.nowLanguageData = "tranditional";
      }
      if (newValue == "en") {
        this.nowLanguageData = "EN";
      }

      this.handleCurrentPageChange(0);
    },
  },
};
</script>

<style lang='less' scoped>
header + a img {
  max-width: 100%;
  width: 100%;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2b426a;
}
.banner {
  position: relative;
}

.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

figure,
article {
  margin: 1.2em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

figure figcaption {
  background: #165da3;
  font-size: 1.2em;
  color: #fff;
  font-weight: 500;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
}

figure div {
  padding: 0.6em 0;
  display: flex;
  flex-wrap: wrap;
}

figure div a {
  display: flex;
  flex-direction: column;
  width: 31%;
  margin-right: 3.5%;
  margin-bottom: 2em;
}

figure div a:nth-child(3),
figure div a:nth-child(6),
figure div a:nth-child(9),
figure div a:nth-child(12) {
  margin-right: 0;
}

figure div a div {
  max-width: 24em;
  max-height: 16em;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

figure div a div img {
  width: 100%;
}

figure div a strong {
  width: 100%;
  word-wrap: break-word; /*强制换行*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*隐藏后添加省略号*/
  white-space: nowrap; /*强制不换行*/
  height: 2em;
  /* overflow: hidden; */
  color: #595757;
  line-height: 1.4em;
  font-weight: 700;
}

figure div a span {
  font-size: 0.9em;
  line-height: 2em;
  padding-bottom: 0.4em;
  margin-bottom: 0.4em;
  color: #898989;
}

figure div a p {
  /* line-height: 2.6em;
  margin-bottom: 0.4em; */
  /* height: 5.2em; */
  //   margin-top: 0.8em;
  //    font-size: .6em;
  //   margin-bottom: 0.4em;
  //   color: #595757;
  //   height: 5.2em;
  //   // text-overflow: -o-ellipsis-lastline;
  // white-space: nowrap;//规定段落中的文本不进行换行
  // overflow: hidden;//溢出隐藏
  // text-overflow: ellipsis;//裁剪显示省略号
  height: 4.8em;
  color: #333;
  font-size: 28rpx;
  line-height: 40rpx;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.6em;
}

figure > div a:hover strong {
  color: #e60044;
}

.page {
  margin: 2em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.page ul {
  display: flex;
  justify-content: center;
}

.page ul li {
  line-height: 1.3em;
  /* font-size: 1em; */
  padding: 0.4em 1.2em;
  margin-right: 0.6em;
}

.page ul li.thisclass {
  color: #165da3;
  font-weight: bold;
}

.page ul a {
  display: block;
}

.page ul a:last-child {
  margin-right: 0;
}

article {
  margin: 0 auto;
  padding: 3em 0;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
}

article h3 {
  font-weight: bold;
}

article h1,
article h2 {
  text-align: center;
}

article h1 {
  color: #165da3;
  font-weight: 500;
  font-size: 1.6em;
}

article h1:after,
article h2:after {
  margin: 0 auto 0 auto;
  width: 1.5em;
  height: 0.4em;
  content: "";
  display: block;
}

article dfn {
  font-size: 0.9rem;
  text-align: center;
  font-style: normal;
  color: #898989;
  display: block;
}

article > div {
  margin-top: 2em;
  padding: 1em 0.5em;
  line-height: 2;
}

article > div img {
  max-width: 800px;
  width: 100%;
  height: auto;
}

article > div > * {
  margin-top: 1em;
}

article > div > h2 {
  margin-top: 3em;
}

article > div ul {
  width: 100%;
  overflow: hidden;
}

article > div li {
  float: left;
  display: inline;
  padding: 1em 0;
  min-width: 25%;
  max-width: 100%;
  line-height: 1.5;
}

article > div li strong {
  color: #2693d0;
  display: block;
}

article > div dl {
  width: 95%;
  border-left: #2693d0 1px solid;
}

article > div dl dd {
  padding-left: 1em;
  position: relative;
}

article > div dl dd + dd {
  margin-top: 1em;
}

article > div dl dd span {
  display: block;
  font-weight: bold;
  color: #2693d0;
}

article > div dl dd:before {
  width: 0.5em;
  height: 0.5em;
  content: "";
  background-color: #f00;
  position: absolute;
  top: 0.8em;
  left: -0.25em;
  z-index: 2;
  display: block;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure {
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure div a strong {
    margin-top: 0;
  }

  figure div a p {
    line-height: 2.3em;
    height: 6.8em;
  }
}

@media screen and (max-width: 1150px) {
  figure div a span {
    font-size: 1em;
  }

  figure div a strong {
    line-height: 1em;
  }

  figure div a p {
    line-height: 2em;
  }
}

@media screen and (max-width: 768px) {
  figure div {
    display: flex;
    justify-content: space-between;
  }

  figure div a {
    width: 100%;
  }

  figure div a div {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }

  figure div a strong {
    margin-top: 0;
  }
  figure div a div img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  figure div a p {
    height: 5.8em;
  }
}
</style>
