const action = {
  GET: "get",
  PUT: "put",
  DELETE: "delete",
  POST: "post",
};
export default action;


export const PageType = {
  INDEX: '/',
  QIYEJIANJIE: '/aboutus/synopsis',
  LICHENGBEI: '/aboutus/milestone',
  QIYERONGYU: '/aboutus/Honor',
  SHEHUIZEREN: '/aboutus/responsibility',
  QIXIAYIYUAN: '/hospitals',
  KAIZHANYEWU: '/chapterseven',
  GONGSIZILIAO: '/investors/companyprofile',
  SHANGSHIWENJIAN: '/investors/listingdocument',
  CAIWUBAOGAO: '/investors/financialreports',
  GONGSITUIJIECAILIAO: '/investors/materials',
  GUPIAOXINXI: '/investors/stockmsg',
  TOUZIZHEGUANXIRILI: '/investors/investorcalendar',
  XINWENGAO: '/investors/newsrelease',
  QIYEGUANZHI: '/investors/corporategovernance',
  GONGGAOJITONGHAN: '/investors/notice',
  TOUZIZHECHAXUN: '/investors/query',
  KEHUZIXUN: '/contactus/default',
  GUANZHUWOMEN: '/contactus/counsel',
  // 新增
  TOUZIZHEGUANXI: '/investors/default',
  YOUJIANDINGYUE:'/investors/email',
  GAOGUANXINGXI:'/aboutus/executives',
  XINWENZHONGXIN:'/article/list?type=1',
  WENZHANGXIANGQIN:'/news/newsinfo'
}
